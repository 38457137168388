@font-face {
  font-family: circularNormal;
  src: url("font/circular-std-6.ttf");
}

@font-face {
  font-family: circularBold;
  src: url("font/circular-std-4.ttf");
}

@font-face {
  font-family: circularItalic;
  src: url("font/circular-std-7.ttf");
}

.fontNormal {
  font-family: circularNormal;
}

.fontBold {
  font-family: circularBold;
}

.fontItalic {
  font-family: circularItalic;
}

.fontSecondaryText {
  font-family: montserrat,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
}

.whiteBodyText { 
  color: white;
  font-size: clamp(15px, 2vw, 35px);
}

.whiteSubtitleText { 
  color: white;
  font-size: clamp(22px, 3vw, 52px);
}

.blackBodyText { 
  color: white;
  font-size: clamp(22px, 3vw, 52px);
}